import React from "react";
const ChildPageContainer = ({children, wide = false, lastItem = true}) => {
  return (
     <section className="section" style={{marginTop:-150, marginBottom:lastItem ? 0 : 300}}>
   
       <div className="container-fluid bring-to-front py-md-0" style={{maxWidth:1300}}>
         <div className={"shadow bg-contrast p-5 rounded"}>
            {children}
         </div>
       </div>
     </section>
  )
};

export default ChildPageContainer;
