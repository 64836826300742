import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import logoLight from "assets/img/logo3_white.svg";

// reactstrap components
import { NavLink, Nav, Container, Row, Col } from "reactstrap";

const DefaultFooter = () => {
  return (
    <footer className="site-footer section bg-dark text-contrast edge top-left">
      <Container className="py-3">
        <Row className="row gap-y text-center text-md-left">
          <Col md="4" className="mr-auto">
            <img
              src={logoLight}
              alt=""
              className="logo"
            />

            <p>
              SendBoth, the next-level SMS (& Email) Marketing Automation Platform
            </p>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              <p className="nav-link">
                Comparisons
              </p>
              <NavLink className="py-2 text-contrast" href="../">
                MailChimp
              </NavLink>
              <NavLink className="py-2 text-contrast" href="../">
                SendGrid
              </NavLink>
              <NavLink className="py-2 text-contrast" href="../">
                Constant Contact
              </NavLink>
            </Nav>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              <p className="nav-link">
                Product
              </p>
              <NavLink className="py-2 text-contrast" href="../#features">
                Features
              </NavLink>
              <NavLink className="py-2 text-contrast" href="../careers/">
                API
              </NavLink>
              <NavLink className="py-2 text-contrast" href="../prices/">
                Pricing
              </NavLink>
            </Nav>
          </Col>

          <Col md="2">
            <Nav className="flex-column" tag="nav">
              <p className="nav-link">
                Resources
              </p>
              <NavLink className="nav-item py-2 text-contrast" href="../careers/">
                Careers
              </NavLink>
              <NavLink className="nav-item py-2 text-contrast" href="mailto:hello@sendboth.com">
                Contact
              </NavLink>
              <NavLink className="nav-item py-2 text-contrast" href="../blog/">
                Blog
              </NavLink>
            </Nav>
          </Col>

          <Col md="2">
            <h6 className="py-2 small">Follow us</h6>

            <nav className="nav justify-content-around">
              <a
                href="https://facebook.com/SendBoth"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-circle btn-sm brand-facebook"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} />
              </a>
              <a href="https://twitter.com/SendBoth" className="btn btn-circle btn-sm brand-twitter">
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </a>
              <a href="https://instagram.com/SendBoth" className="btn btn-circle btn-sm brand-instagram">
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
            </nav>
          </Col>
        </Row>

        <hr className="mt-5 op-5" />

        <Row className="small">
          <Col md="4">
            <p className="mt-2 mb-0 text-center text-md-left">
              ©&nbsp;&nbsp;{new Date().getFullYear()}&nbsp;&nbsp;
              <a
                href="https://SendBoth.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                SendBoth
              </a>
              &nbsp;&nbsp;&nbsp;All Rights Reserved.
              &nbsp;
              &nbsp;
              &nbsp;
              <a
                 href="../terms"
                 target="_blank"
                 rel="noopener noreferrer"
              >
                Privacy & Terms
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default DefaultFooter;
