import React from "react";
import { Container, Row, Col } from "reactstrap";
import Fade from "react-reveal/Fade";

const animations = [
  { duration: 1500, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 200 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 100 },
  { duration: 1000, delay: 300 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 2000, delay: 500 },
  { duration: 500, delay: 200 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 0 },
  { duration: 500, delay: 100 },
  { duration: 500, delay: 0 }
];

const ChildPageHeading = ({title, subtitle, summary, children}) => {
  return (
    <header className="header alter1-header section text-contrast">
      <div className="shapes-container">
        {animations.map((el, i) => (
          <Fade bottom duration={el.duration} delay={el.delay} key={i}>
            <div className="shape shape-animated shape-diagonal" key={i} />
          </Fade>
        ))}
      </div>

      <Container className="container">
        <Row>
          <Col md="6">
            <h1 className="text-contrast display-4 display-md-4">
              <span className="bold">{title}</span>
            </h1>
            <p className="lead bold">{subtitle}</p>

            <p className="lead">
              {summary}
            </p>
          </Col>
          
          <Col md="12">
            {children}
          </Col>
          
        </Row>
      </Container>
    </header>
  );
};

export default ChildPageHeading;
