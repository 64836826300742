import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BlogDetailed = ({lastItem = false}) => {
  return (
     <section className="section" style={{marginTop:-150, marginBottom:lastItem ? 0 : 300}}>
   
       <div className="container bring-to-front py-md-0">
         <div className="shadow bg-contrast p-5 rounded">
           <h1>5 tips to maximize your next launch</h1>
           <i className="fas fa-exclamation-circle fa-3x accent"></i>
           <a
            href="/blog/123" className="mt-4 accent d-flex align-items-center">
             <h5 className="mr-3">October 2022</h5>
  
             <span className="rounded-pill shadow-box text-dark bold py-2 px-4">
              <FontAwesomeIcon
                 icon={["far", "lightbulb"]}
                 className="text-primary mr-2"
              />
              <span className="text-primary">Marketing & Growth Tips</span>
            </span>
              &nbsp;&nbsp;
             <span className="rounded-pill shadow-box text-dark bold py-2 px-4">
              <FontAwesomeIcon
                 icon={["far", "lightbulb"]}
                 className="text-primary mr-2"
              />
              <span className="text-primary">SMS Automation</span>
            </span>
         </a>
           <hr/>
           <h2>1. Start your Email List</h2>
           <p className="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
             Quam adipiscing vitae proin sagittis nisl rhoncus. Sed egestas egestas fringilla phasellus faucibus scelerisque.
             Accumsan in nisl nisi scelerisque eu ultrices vitae. Vitae nunc sed velit dignissim sodales ut eu.
             Ac ut consequat semper viverra. Amet mauris commodo quis imperdiet massa. Vitae sapien pellentesque habitant morbi tristique
             ... [continued]
           </p>
           <hr className="mt-5"/>
           <a
              href="/blog/123" className="mt-4 d-flex align-items-center">
             <h5 className="mr-4">Read this post</h5>
             <h5 className="mr-4">8 minute read</h5>
           </a>
           
         </div>
       </div>
     </section>
  )
};

export default BlogDetailed;
