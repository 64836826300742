import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames/bind";

// reactstrap components
import {NavbarBrand, NavItem, NavLink, Nav, Container} from "reactstrap";

import logoLight from "assets/img/logo3_white.svg";
import logo from "assets/img/logo3_orange.svg";
import MoreInfoButton from "../MoreInfoButton";

class DefaultNavbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navbarExpanded: false
    };

    this.navbar = React.createRef();

    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleNavbar() {
    this.setState(prevState => ({
      navbarExpanded: !prevState.navbarExpanded
    }));
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const navigation = this.navbar.current;
    const navTop = navigation.offsetTop + navigation.offsetHeight;

    if (window.scrollY >= navTop) {
      navigation.classList.add("navbar-sticky");
    } else {
      navigation.classList.remove("navbar-sticky");
    }
  };

  render() {
    const useOnlyDarkLogo = this.props.useOnlyDarkLogo;

    return (
      <nav
        ref={this.navbar}
        className={classNames(
          "navbar navbar-expand-md main-nav navigation fixed-top sidebar-left",
          { "navbar-expanded": this.state.navbarExpanded }
        )}
      >
        <Container>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleNavbar}
          >
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
            {useOnlyDarkLogo ? (
              <img
                alt="..."
                src={logo}
                className="logo logo-sticky"
              />
            ) : (
              <>
                <img
                   style={{width:265,maxWidth:265,maxHeight:'auto'}}
                  src={logo}
                  alt="SendBoth"
                  className="logo logo-sticky d-block d-md-none"
                />
                <img
                   style={{width:265,maxWidth:265,maxHeight:'auto'}}
                  src={logoLight}
                  alt="SendBoth"
                  className="logo d-none d-md-block"
                />
              </>
            )}
          </NavbarBrand>

          <div className="collapse navbar-collapse">
            <div className="sidebar-brand">
              <NavLink to="../">
                <img
                  src={logo}
                  alt="SendBoth"
                  className="logo"
                />
              </NavLink>
            </div>

            <Nav className="nav navbar-nav ml-auto" navbar>
              <NavItem>
                <NavLink href="../#product">Product</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="../#features">Features</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="../pricing">Pricing</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="../#why">Why SendBoth?</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="../#faq">FAQ</NavLink>
              </NavItem>
              <NavItem className={'d-md-none'}>
                <NavLink href="../blog">Blog</NavLink>
              </NavItem>
              <NavItem className={'d-md-none'}>
                <NavLink href="../careers">Careers</NavLink>
              </NavItem>
              <NavItem
                 style={{marginTop:-32}}>
                <MoreInfoButton
                   className="btn btn-outline-dark bold"
                   text="Get Started"
                   to="https://app.sendboth.com"
                />
              </NavItem>
            </Nav>
  
          </div>
        </Container>
      </nav>
    );
  }
}

export default DefaultNavbar;
