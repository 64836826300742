import React, {useRef} from "react";

// shared page components
import StartFree from "../shared/StartFree.jsx";

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

// alternative 3 page components
import Heading from "../alter3/BlogHeading.jsx";
import HeadingDetailed from "../alter3/BlogHeadingDetailed.jsx";
import Contact from "../shared/Contact";
import BlogDetailed from "../shared/BlogDetailed";
import {useParams} from "react-router-dom";
import BlogList from "../shared/BlogList";

const BlogLanding = () => {
   const mainRef = useRef();
   const { id } = useParams();
     return (
      <>
        <DefaultNavbar />
        <main ref={mainRef}>
           {/* Alternative 3 specific components */}
   
           {id ? <HeadingDetailed/> : <Heading/>}
           {id ? <BlogDetailed/> :
              <>
              <BlogList/>
              <BlogList lastItem={true}/>
           </>}
          <StartFree />
          <Contact />
          {/*<Subscribe/>*/}
        </main>
        <DefaultFooter />
      </>
    );
}

export default BlogLanding;
