import React from "react";
const PrivacyTermsFull = () => {
  return (
     <>
       <h1>SendBoth Privacy, Terms, & Acceptable Use</h1>
       <i className="fas fa-exclamation-circle fa-3x accent"></i>
       <a
          href="/blog/123" className="mt-4 accent d-flex align-items-center">
         <h5 className="mr-3">Last Revised: October 2022</h5>
       </a>
        <br/>
        <hr/>
        <h5 className="mr-3">Privacy</h5>
        <p>
           We do not store any cookies. We only store the data you choose to upload. You have full capabilities to delete/manage/export your data.
        </p>
        <h5 className="mr-3">Terms</h5>
        <p>
           We offer a refund if you have used less than 5% of your "Total Credits".<br/>
           "Total Credits" is defined as the total number of SMS Credits and total number of Email Credits assigned to original plan.
        </p>
        <h5 className="mr-3">Acceptable Use</h5>
        <p>
           We reserve the right to decline service without refund to anyone deemed to be spreading spam or causing harm to the platform.
        </p>
     </>
  )
};

export default PrivacyTermsFull;
