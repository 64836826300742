import React from "react";
const renderIcons = () => {
  let icons = [];
  const items = [
    { icon: "7s-hourglass", prefix: "pe" },
    { icon: "7s-magic-wand", prefix: "pe" },
    { icon: "7s-rocket", prefix: "pe" },
    { icon: "7s-plugin", prefix: "pe" },
    { icon: "7s-like", prefix: "pe" },
    { icon: "7s-clock", prefix: "pe" },
    { icon: "7s-smile", prefix: "pe" },
    { icon: "7s-piggy", prefix: "pe" },
    { icon: "7s-shield", prefix: "pe" },
    { icon: "7s-server", prefix: "pe" }
  ];
  items.forEach((icon, i) => {
    icons.push(
       <i
          className={`icon ${icon.prefix} ${icon.prefix}-${icon.icon} ${
             icon.prefix
          }-3x`}
          key={i}
       />
    );
  });
  
  return icons;
};

const BlogDetailed = () => {
  return (
     <section className="section start-free" style={{marginTop:-150}}>
       <div className="shapes-container">{renderIcons()}</div>
  
       <div className="container bring-to-front py-md-0">
         <div className="shadow bg-contrast p-5 rounded">
           <i className="fas fa-exclamation-circle fa-3x accent"></i>
           <a
            href="#!" className="mt-4 accent d-flex align-items-center">
             <h5 className="mr-3">October 2022&nbsp;&nbsp;&nbsp;|&nbsp;</h5>
             <h5 className="mr-4">8 minute read</h5>
         </a>
           <hr/>
           <h2>1. Start your Email List</h2>
           <p className="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam adipiscing vitae proin sagittis nisl rhoncus. Sed egestas egestas fringilla phasellus faucibus scelerisque. Accumsan in nisl nisi scelerisque eu ultrices vitae. Vitae nunc sed velit dignissim sodales ut eu. Ac ut consequat semper viverra. Amet mauris commodo quis imperdiet massa. Vitae sapien pellentesque habitant morbi tristique. A arcu cursus vitae congue mauris rhoncus aenean vel elit. Ornare lectus sit amet est placerat. Pellentesque pulvinar pellentesque habitant morbi tristique senectus et. Ornare lectus sit amet est placerat in egestas erat. Curabitur vitae nunc sed velit dignissim sodales ut eu.</p>
           
           <h2>2. Start your Email List</h2>
           <p className="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam adipiscing vitae proin sagittis nisl rhoncus. Sed egestas egestas fringilla phasellus faucibus scelerisque. Accumsan in nisl nisi scelerisque eu ultrices vitae. Vitae nunc sed velit dignissim sodales ut eu. Ac ut consequat semper viverra. Amet mauris commodo quis imperdiet massa. Vitae sapien pellentesque habitant morbi tristique. A arcu cursus vitae congue mauris rhoncus aenean vel elit. Ornare lectus sit amet est placerat. Pellentesque pulvinar pellentesque habitant morbi tristique senectus et. Ornare lectus sit amet est placerat in egestas erat. Curabitur vitae nunc sed velit dignissim sodales ut eu.</p>
           
           <h2>3. Start your Email List</h2>
           <p className="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam adipiscing vitae proin sagittis nisl rhoncus. Sed egestas egestas fringilla phasellus faucibus scelerisque. Accumsan in nisl nisi scelerisque eu ultrices vitae. Vitae nunc sed velit dignissim sodales ut eu. Ac ut consequat semper viverra. Amet mauris commodo quis imperdiet massa. Vitae sapien pellentesque habitant morbi tristique. A arcu cursus vitae congue mauris rhoncus aenean vel elit. Ornare lectus sit amet est placerat. Pellentesque pulvinar pellentesque habitant morbi tristique senectus et. Ornare lectus sit amet est placerat in egestas erat. Curabitur vitae nunc sed velit dignissim sodales ut eu.</p>
           
           <h2>4. Start your Email List</h2>
           <p className="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam adipiscing vitae proin sagittis nisl rhoncus. Sed egestas egestas fringilla phasellus faucibus scelerisque. Accumsan in nisl nisi scelerisque eu ultrices vitae. Vitae nunc sed velit dignissim sodales ut eu. Ac ut consequat semper viverra. Amet mauris commodo quis imperdiet massa. Vitae sapien pellentesque habitant morbi tristique. A arcu cursus vitae congue mauris rhoncus aenean vel elit. Ornare lectus sit amet est placerat. Pellentesque pulvinar pellentesque habitant morbi tristique senectus et. Ornare lectus sit amet est placerat in egestas erat. Curabitur vitae nunc sed velit dignissim sodales ut eu.</p>
           
           <h2>5. Start your Email List</h2>
           <p className="mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quam adipiscing vitae proin sagittis nisl rhoncus. Sed egestas egestas fringilla phasellus faucibus scelerisque. Accumsan in nisl nisi scelerisque eu ultrices vitae. Vitae nunc sed velit dignissim sodales ut eu. Ac ut consequat semper viverra. Amet mauris commodo quis imperdiet massa. Vitae sapien pellentesque habitant morbi tristique. A arcu cursus vitae congue mauris rhoncus aenean vel elit. Ornare lectus sit amet est placerat. Pellentesque pulvinar pellentesque habitant morbi tristique senectus et. Ornare lectus sit amet est placerat in egestas erat. Curabitur vitae nunc sed velit dignissim sodales ut eu.</p>
           
           
           
           
           
           <hr className="mt-5"/>
             <p className="small bold" style={{maxWidth:'70%'}}>
                   <img
                      src="https://cdn3.iconfinder.com/data/icons/web-design-and-development-2-6/512/87-1024.png"
                      className="img-fluid img-thumbnail"
                      alt=""
                      style={{float:'left', width:60, height:60, borderRadius:30, marginRight:20}} />
               By Cameron Scott.
               <br/>Cameron is the CMO of SendBoth. When he’s not busy creating engaging content and building the SendBoth brand, he spends his time reading blog posts and listening to podcasts.
             </p>
             
         </div>
       </div>
     </section>
  )
};

export default BlogDetailed;
