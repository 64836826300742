import React from "react";
import classNames from "classnames/bind";
import { Col, ListGroup, ListGroupItem } from "reactstrap";
import MoreInfoButton from "components/MoreInfoButton";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-minimal.css";

const PricingPlan = props => {
  const plan = props;

  return (<>
    {
       ((plan.name !== 'enterprise' && !plan.extended) || plan.extended) &&
    <Col
      lg={plan.name === 'enterprise' || plan.name === 'premium' ? 12 : 3}//4
      className={classNames(`pricing-plan plan-${plan.name} ${plan.class}`, {
        "best-value": plan.best,
      })}
      style={{
        minHeight: plan.extended ? plan.name === 'enterprise' ? 200 : 850 : 0,
        display: plan.name === 'enterprise' ? 'flex' : 'inherit',
        flexWrap: plan.name === 'enterprise' ? 'wrap' : 'inherit',
      }}
    >
      <div className={classNames("p-2", { "py-md-6": plan.extended ? false : plan.best })}>
        <div className="text-center">
          <h4
            className={`bold text-capitalize text-${
              plan.best ? "primary" : "alternate"
            }`}
          >
            {plan.name === 'enterprise' && <br/>}
            {plan.name}
          </h4>
          <p>{plan.desc}</p>

          <div className="pricing-details">
            <span className="pricing-value">
              {plan.name === 'enterprise' ? <span style={{fontSize:plan.extended ? 40: 50,position:'relative',top:plan.extended ? -10: 0}}>Contact Us</span> : <span className="price">
                <Odometer duration={500} value={plan.price[props.basis]}/>
              </span>}
            </span>
          </div>

          <MoreInfoButton
            className={`btn btn-${plan.best ? "" : "outline-"}alternate mb-3`}
            text={plan.extended ? "I Want This" : "Learn More"}
            to={plan.extended ? "https://app.sendboth.com" : "../pricing"}
          />
        </div>
  
        {plan.name !== 'enterprise' && <hr/>}
  
        {plan.name !== 'enterprise' && <div className="d-none d-md-flex">
          <ListGroup flush className="list-group-no-border">
            <ListGroupItem>Page Views <span className="credit-value">(Credits: {plan.page_views})</span></ListGroupItem>
            <ListGroupItem>Contacts <span className="credit-value">(Credits: {plan.subscribers})</span></ListGroupItem>
            <ListGroupItem>Email <span className="credit-value">(Credits: {plan.email_credits})</span></ListGroupItem>
            <ListGroupItem>SMS <span className="credit-value">(Credits: {plan.sms_credits})</span></ListGroupItem>
            <ListGroupItem>AI Generation <span className="credit-value">(Credits: {plan.ai_credits})</span></ListGroupItem>
            {plan.team_member_price != 0 && <ListGroupItem>Unlimited Team Members <span
               className="credit-value">(+${plan.team_member_price}/user)</span></ListGroupItem>}
            {plan.extended ? <>
              {plan.features.map((feature, index) => {
                return <ListGroupItem key={index}>{feature}</ListGroupItem>
              })}
            </> : <></>}
          </ListGroup>
        </div>}
  
        {plan.name !== 'enterprise' && <div className="d-md-none">
          <ListGroup flush className="text-center">
            <ListGroupItem className="border-top-0">
              {plan.subscribers}
            </ListGroupItem>
            <ListGroupItem>Contacts <span>(Credits: {plan.subscribers})</span></ListGroupItem>
            <ListGroupItem>Send SMS <span>(Credits: {plan.sms_credits})</span></ListGroupItem>
            <ListGroupItem>Send Email <span>(Credits: {plan.email_credits})</span></ListGroupItem>
            {plan.features.map((feature, index) => {
              return <ListGroupItem key={index}>{feature}</ListGroupItem>
            })}
          </ListGroup>
        </div>}
      </div>
      {plan.name === 'enterprise' && <div className={classNames(plan.extended ? "p-5 row" : "p-4 row", { "py-md-6": plan.extended ? false : plan.best })}>
        
        <div className="d-none d-md-flex">
          <ListGroup flush className="list-group-no-border">
            <ListGroupItem>Page Views <span>(Credits: {plan.page_views})</span></ListGroupItem>
            <ListGroupItem>AI Generation <span>(Credits: {plan.ai_credits})</span></ListGroupItem>
            <ListGroupItem>HIPAA Compliant</ListGroupItem>
          </ListGroup>
        </div>
        <div className="d-none d-md-flex">
          <ListGroup flush className="list-group-no-border">
            <ListGroupItem>Contacts <span>(Credits: {plan.subscribers})</span></ListGroupItem>
            <ListGroupItem>SMS <span>(Credits: {plan.sms_credits})</span></ListGroupItem>
            <ListGroupItem>First-Party Data</ListGroupItem>
          </ListGroup>
        </div>
        <div className="d-none d-md-flex">
          <ListGroup flush className="list-group-no-border">
            <ListGroupItem>Email <span>(Credits: {plan.email_credits})</span></ListGroupItem>
            <ListGroupItem>Surveys & Product Quizzes</ListGroupItem>
            <ListGroupItem>Everything on the above Plans!</ListGroupItem>
          </ListGroup>
        </div>
  
        <div className="d-md-none">
          <ListGroup flush className="text-center">
            <ListGroupItem className="border-top-0">
              {plan.subscribers}
            </ListGroupItem>
            <ListGroupItem>Contacts <span>(Credits: {plan.subscribers})</span></ListGroupItem>
            <ListGroupItem>Send SMS <span>(Credits: {plan.sms_credits})</span></ListGroupItem>
            <ListGroupItem>Send Email <span>(Credits: {plan.email_credits})</span></ListGroupItem>
            {plan.features.map((feature,index)=>{
              return <ListGroupItem key={index}>{feature}</ListGroupItem>
            })}
          </ListGroup>
        </div>
      </div>}
    </Col>
    }</>
  );
};

export default PricingPlan;
