import React from "react";
import {Container, Row, Col, NavLink} from "reactstrap";

const renderIcons = () => {
  let icons = [];
  const items = [
    { icon: "7s-hourglass", prefix: "pe" },
    { icon: "7s-magic-wand", prefix: "pe" },
    { icon: "7s-rocket", prefix: "pe" },
    { icon: "7s-plugin", prefix: "pe" },
    { icon: "7s-like", prefix: "pe" },
    { icon: "7s-clock", prefix: "pe" },
    { icon: "7s-smile", prefix: "pe" },
    { icon: "7s-piggy", prefix: "pe" },
    { icon: "7s-shield", prefix: "pe" },
    { icon: "7s-server", prefix: "pe" }
  ];
  items.forEach((icon, i) => {
    icons.push(
      <i
        className={`icon ${icon.prefix} ${icon.prefix}-${icon.icon} ${
          icon.prefix
        }-3x`}
        key={i}
      />
    );
  });

  return icons;
};

const StartFree = () => {
  return (
    <section className="section start-free">
      <div className="shapes-container">{renderIcons()}</div>

      <Container>
        <Row>
          <Col md="6" className="mx-auto">
            <div className="section-heading text-center">
              <i className="pe pe-7s-unlock fa-3x text-alternate" />
              <h2 className="heading-line bold mt-4">
                Start your 60-Day Free Trial now!
              </h2>
              <p className="lead text-muted">
                Every new customer receives a no questions asked refund if less than 5% of credits have been used.{" "}
                <span className="italic">
                  Don't let this offer pass!
                </span>
              </p>
            </div>
          </Col>
        </Row>

        <div className="nav flex-column flex-md-row align-items-center justify-content-center">
          <NavLink
            href="https://app.sendboth.com"
            className="btn btn-alternate btn-lg py-3 px-4 bold mr-0 mr-md-3 mb-3 mb-md-0"
          >
            Sign up
          </NavLink>
          <NavLink
             href="mailto:hello@sendboth.com"
            className="btn btn-outline-dark btn-lg py-3 px-4 bold"
          >
            Contact us
          </NavLink>
        </div>
      </Container>
    </section>
  );
};

export default StartFree;
