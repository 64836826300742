import React, {useRef} from "react";

// shared page components
import StartFree from "../shared/StartFree.jsx";

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

import Contact from "../shared/Contact";
import ChildPageContainer from "../shared/ChildPageContainer";
import ChildPageHeading from "../alter3/ChildPageHeading";
import MoreInfoButton from "../../components/MoreInfoButton";

const availablePositions = [
   {
      title:'Customer Support',
      description:'Help us provide best-in-class customer service to our customers.',
   },
   {
      title:'Content Intern',
      description:'Love to write or create content? We could use your help!',
   },
   {
      title:'Content Marketing Manager',
      description:'An editor at heart? Love to write or create content? We could use your help!',
   },
   {
      title:'Marketing Associate',
      description:'Want to put your skills to the test? Jump into a fast-paced environment.',
   },
   {
      title:'Chief Marketing Officer',
      description:'Think you know more than us? We\'d love to hear from you!',
   },
   {
      title:'Enterprise Sales',
      description:'Love to put your network to use? Step up to this lucrative challenge.',
   },
   {
      title:'Software Engineer - Contract',
      description:'Are you a React or Rails engineer looking for a side hustle? Work on our modern tech-stack with a huge focus on DX (dev experience).',
   },
]

const CareersPage = () => {
   const mainRef = useRef();
     return (
      <>
        <DefaultNavbar />
        <main ref={mainRef}>
           <ChildPageHeading title={'Careers'}/>
           <ChildPageContainer>
              <>
                 <h1>Ready to Join Our Team?</h1>
                 <br/>
                 <hr/>
                 {
                    availablePositions.map((position,index)=>{
                       return <div key={index}>
                          <h5 className="mr-3">{position.title}</h5>
                          <p>
                             {position.description}
                             <br/>
                             <MoreInfoButton
                                className="btn btn-outline-primary bold"
                                text="Apply"
                                to={"mailto:hello@sendboth.com?subject=SendBoth Careers - " + position.title}
                             />
                          </p>
                       </div>
                    })
                 }
              </>
           </ChildPageContainer>
          <StartFree />
          <Contact />
          {/*<Subscribe/>*/}
        </main>
        <DefaultFooter />
      </>
    );
}

export default CareersPage;
