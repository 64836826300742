import React, {useRef} from "react";

// shared page components
import StartFree from "../shared/StartFree.jsx";

// core components
import DefaultNavbar from "components/Navbars/DefaultNavbar.jsx";
import DefaultFooter from "components/Footers/DefaultFooter.jsx";

import Contact from "../shared/Contact";
import ChildPageContainer from "../shared/ChildPageContainer";
import ChildPageHeading from "../alter3/ChildPageHeading";
import PrivacyTermsFull from "../shared/PrivacyTermsFull";

const PrivacyPage = () => {
   const mainRef = useRef();
     return (
      <>
        <DefaultNavbar />
        <main ref={mainRef}>
           <ChildPageHeading title={'Terms & Privacy'}/>
           <ChildPageContainer>
              <PrivacyTermsFull/>
           </ChildPageContainer>
          <StartFree />
          <Contact />
          {/*<Subscribe/>*/}
        </main>
        <DefaultFooter />
      </>
    );
}

export default PrivacyPage;
