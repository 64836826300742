import React from "react";
import { Container, Row, Col, UncontrolledCollapse } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fade from "react-reveal/Fade";

const questions = [
  {
    question: "What do you mean, a 60-Day Refund?",
    answer:
      "Not satisfied with your experience? As long as you have used less than 5% of your Credits, we will refund you with no questions asked!"
  },
  {
    question: "What if I chose the wrong plan?",
    answer:
      "You may change or cancel your plan at any time via your account settings."
  },
  {
    question: "What is the typical response time for a support question?",
    answer:
      "We try to make ourselves available 8am - 8pm, every day of the week. Our premium plans also include emergency support."
  },
  {
    question: "What do I need to know to customize a template?",
    answer:
      "All of our email & form templates can easily be edited with no-code or full-custom code!"
  },
  {
    question: "Can I suggest a new feature?",
    answer:
      "Definitely YES, we are always looking for ways to improve our service."
  }
];

const Faqs = () => {
  return (
    <section className="section faqs-dd bg-light edge top-left" id={'faq'}>
      <div className="shapes-container">
        <div className="absolute icon">
          <Fade top left>
            <FontAwesomeIcon icon={["fas", "question"]} />
          </Fade>
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <h2 className="heading-line">Frequently Asked Questions</h2>
          <p className="lead">Want to know more about our services?</p>
        </div>

        <Row>
          <Col lg="8" className="mx-lg-auto">
            {questions.map((faq, i) => (
              <Fade bottom key={i}>
                <div className="card shadow-box shadow-hover mb-3" key={i}>
                  <div className="card-header py-3">
                    <Link
                      to="#"
                      className="card-title collapsed"
                      id={`toggler-${i}`}
                    >
                      {faq.question}
                    </Link>
                  </div>

                  <UncontrolledCollapse toggler={`#toggler-${i}`}>
                    <div className="card-body">{faq.answer}</div>
                  </UncontrolledCollapse>
                </div>
              </Fade>
            ))}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Faqs;
