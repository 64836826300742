import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { Link } from "react-router-dom";
import {NavLink} from "reactstrap";

const MoreInfoButton = props => {
  return (
    <NavLink
       href={props.to} style={{display: 'inline-block'}} className={`more-link ${props.className}`}>
      {props.text}
      <FontAwesomeIcon
        icon={["fas", "long-arrow-alt-right"]}
        className="icon"
      />
    </NavLink>
  );
};

export default MoreInfoButton;
