import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import tablet1 from "assets/img/screens/tablet/1.png";
import app1 from "assets/img/screens/app/1.png";
import {NavLink} from "reactstrap";
const renderFeatures = () => {
  let features = [];
  const items = [
    {
      name: "Segmentations",
      icon: "plug",
      description: "Powerful segmentations to deliver personalized experiences."
    },
    {
      name: "Marketing",
      icon: "heart",
      description: "SMS & Email marketing with A/B Split-Testing, Recurring Drip Campaigns & more!"
    },
    {
      name: "Support",
      icon: "headphones",
      description: "Dedicated chat & email support, with an assigned account manager for our premium customers."
    }
  ];

  items.forEach((f, i) => {
    features.push(
      <li className="list-item" key={i}>
        <div className="media align-items-center">
          <div className="icon-shape mr-3">
            <div className="shape shape-pipes" />
            <FontAwesomeIcon
              icon={["fas", f.icon]}
              className="icon fa-3x text-alternate"
            />
          </div>

          <div className="media-body">
            <h5 className="bold">{f.name}</h5>
            <p className="my-0">{f.description}</p>
          </div>
        </div>
      </li>
    );
  });

  return features;
};

const WhyUs = () => {
  return (
    <section className="section why-choose-us">
      <div className="shapes-container">
        <div className="pattern pattern-dots" />
      </div>

      <div className="container pb-8 bring-to-front">
        <div className="section-heading text-center">
          <h2 className="heading-line">Why you should choose SendBoth?</h2>
          <p className="text-muted lead mx-auto">
            SendBoth is designed to provide you with next-level data to enable you to make the most impactful decisions.
            It will help you focus on your product, automate growth and reach more paying customers.
          </p>
        </div>

        <div className="row gap-y">
          <div className="col-md-6">
            <h4 className="bold text-alternate">
              It all start by choosing the right tools
            </h4>
            <p className="text-muted lead mb-5">
              When you're looking for a template you want it to stand-out. SendBoth
              comes with many customizable components.
            </p>

            <ul className="list-unstyled why-icon-list">{renderFeatures()}</ul>

            <hr className="my-5" />
            <div className="text-center text-md-left">
              <NavLink href="#faq" className="btn btn-primary text-contrast" style={{display:'inline-block'}}>
                Know More
              </NavLink>
            </div>
          </div>

          <div className="col-md-6">
            <div className="rotated-mockups device-twin">
              <div className="browser absolute shadow-lg">
                <img
                  src={tablet1}
                  alt="..."
                />
              </div>

              <div className="front iphone light">
                <div className="screen">
                  <img
                    src={app1}
                    alt="..."
                  />
                </div>

                <div className="notch" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
