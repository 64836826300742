import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";

// routing components
import "./laapp.js";
import Alternative3 from "views/landing/Alternative3.jsx";

// child pages
import BlogLanding from "./views/landing/BlogLanding";
import PrivacyPage from "./views/landing/Privacy";
import CareersPage from "./views/landing/Careers";
import PricingPage from "./views/landing/Pricing";
import ReactGA from 'react-ga';

ReactGA.initialize('UA-252441591-1');
ReactGA.pageview(window.location.pathname + window.location.search + window.location.hash);

ReactDOM.render(<>
  <BrowserRouter>
    <Routes>
       <Route path="/" element={<Alternative3 />} />
       <Route path="/careers" element={<CareersPage />} />
       <Route path="/pricing" element={<PricingPage />} />
       <Route path="/prices" element={<PricingPage />} />
       <Route path="/privacy" element={<PrivacyPage />} />
       <Route path="/terms" element={<PrivacyPage />} />
       <Route path="/blog/" element={<BlogLanding />} />
       <Route path="/blog/:id" element={<BlogLanding />} />
   
    </Routes>
  </BrowserRouter>
   </>,
  document.getElementById("root")
);
