import React, { useState } from "react";
import { Container, Row, Col, ButtonGroup, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PricingPlan from "./PricingPlan.jsx";
import MoreInfoButton from "components/MoreInfoButton.jsx";

/*
*
    class: "order-md-last",
    class: "order-md-first",
* */
const plans = [
  {
    name: "lite",
    desc: "For hobbyists",
    class: "order-md-first",
    price: { monthly: 50, yearly: 45 },
    subscribers: '10,000',
    page_views: '100,000',
    sms_credits: '1,000',
    email_credits: '50,000',
    ai_credits: '10,000',
    features : [
       'Powerful CRM',
       'Dynamic Segmentations',
       'Contact Lists / Hashtags',
       'Campaigns w/ Scheduling',
       'Responsive & Custom Templates',
       'Embeddable Forms',
       'In-depth Reporting'
    ]
  },
  {
    name: "basic",
    desc: "For individuals",
    price: { monthly: 100, yearly: 90 },
    best: true,
    subscribers: '25,000',
    page_views: '250,000',
    sms_credits: '2,500',
    email_credits: '75,000',
    ai_credits: '25,000',
    features : [
       'Everything on the Lite Plan',
       'Batch Delivery',
       'AI-Optimized Delivery',
       'Time-Warp Delivery',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
    ]
  },
  {
    name: "standard",
    desc: "For startups",
    price: { monthly: 200, yearly: 180 },
    subscribers: '50,000',
    page_views: '500,000',
    sms_credits: '5,000',
    email_credits: '150,000',
    ai_credits: '50,000',
    features : [
      'Everything on the Basic Plan',
      'API Access',
      'White-Labeled Embeds',
    ]
  },
  {
    name: "plus",
    desc: "For small business",
    price: { monthly: 500, yearly: 450 },
    subscribers: '100,000',
    page_views: '1,000,000',
    sms_credits: '10,000',
    email_credits: '500,000',
    ai_credits: '100,000',
    features : [
       'Everything on the Standard Plan',
       'Split-Testing',
       'Recurring / Drip Campaigns',
       'SMS Inbox (Coming Soon)',
    ]
  },
  {
    name: "premium",
    desc: "For high-growth business",
    price: { monthly: 950, yearly: 855 },
    subscribers: '200,000',
    page_views: '2,000,000',
    sms_credits: '20,000',
    email_credits: '1,000,000',
    ai_credits: '200,000',
    features : [
      'Everything on the Plus Plan',
       'Asset Cloud Storage (Coming Soon)',
       'Session Screen Recording (Coming Soon)',
       'Dedicated Support',
    ]
  },
  {
    name: "enterprise",
    desc: "For large companies",
    class: "order-md-last",
    price: { monthly: 0, yearly:0 },
    subscribers: 'Unlimited',
    page_views: 'Unlimited',
    sms_credits: 'Unlimited',
    email_credits: 'Unlimited',
    ai_credits: 'Unlimited',
    features : [
      'Everything on the Premium Plan',
       'HIPAA Compliant',
       'First-Party Data',
       'Surveys',
       'Product Quizzes',
    ]
  },
];
const plans2 = [
  {
    name: "lite",
    desc: "For hobbyists",
    class: "order-md-first",
    price: { monthly: 50, yearly: 45 },
    subscribers: '10,000',
    page_views: '100,000',
    sms_credits: '1,000',
    email_credits: '50,000',
    ai_credits: '10,000',
    team_member_price: '0',
    features : [
       'Powerful CRM',
       'Dynamic Segmentations',
       'Contact Lists / Hashtags',
       'Campaigns w/ Scheduling',
       'Responsive & Custom Templates',
       'Embeddable Forms',
       'In-depth Reporting',
      ' ',
      ' ',
      ' ',
    ]
  },
  {
    name: "basic",
    desc: "For individuals",
    price: { monthly: 100, yearly: 90 },
    best: true,
    subscribers: '25,000',
    page_views: '250,000',
    sms_credits: '2,500',
    email_credits: '75,000',
    ai_credits: '25,000',
    team_member_price: '5',
    features : [
       'Everything on the Lite Plan',
       'Batch Delivery',
       'AI-Optimized Delivery',
       'Time-Warp Delivery',
      'Attach Photos to SMS (MMS)',
      'SMS Inbox',
      'Granular Team Permissions',
      ' ',
    ]
  },
  {
    name: "standard",
    desc: "For startups",
    price: { monthly: 200, yearly: 180 },
    subscribers: '50,000',
    page_views: '500,000',
    sms_credits: '5,000',
    email_credits: '150,000',
    ai_credits: '50,000',
    team_member_price: '10',
    features : [
      'Everything on the Basic Plan',
      'White-Labeled Embeds',
      'Split-Testing (Coming Soon)',
      'Recurring / Drip Campaigns (Coming Soon)',
      'Asset Cloud Storage (Coming Soon)',
      ' ',
      ' ',
      ' ',
    ]
  },
  {
    name: "plus",
    desc: "For small business",
    price: { monthly: 500, yearly: 450 },
    subscribers: '100,000',
    page_views: '1,000,000',
    sms_credits: '10,000',
    email_credits: '500,000',
    ai_credits: '100,000',
    team_member_price: '10',
    features : [
       'Everything on the Standard Plan',
       'API Access',
      'Session Screen Recording (Coming Soon)',
      'Dedicated Support',
      ' ',
      ' ',
      ' ',
      ' ',
      ' ',
    ]
  },
  {
    name: "enterprise",
    desc: "For large companies",
    class: "order-md-last",
    price: { monthly: 0, yearly:0 },
    subscribers: 'Unlimited',
    page_views: 'Unlimited',
    sms_credits: 'Unlimited',
    email_credits: 'Unlimited',
    ai_credits: 'Unlimited',
    features : [
      'Everything on the Premium Plan',
       'HIPAA Compliant',
       'First-Party Data',
       'Surveys (Coming Soon)',
       'Product Quizzes (Coming Soon)',
    ]
  },
];

const Pricing = ({extended=false}) => {
  const [basis, setBasis] = useState("monthly");

  return (
    <section className="section pricing-plans" id={'pricing'}>
      <div className="shapes-container overflow-clear">
        <div className="shape shape-1" />
        <div className="shape shape-2" />
        <div className="shape shape-3" />
        <div className="shape shape-4" />

        <div className="icon-holder">
          <i className="icon pe pe-7s-cash pe-3x" />

          <FontAwesomeIcon
            icon={["fas", "dollar-sign"]}
            className="icon fa-3x"
          />

          <i className="icon pe pe-7s-piggy pe-3x" />

          <i className="icon pe pe-7s-cart pe-3x" />

          <FontAwesomeIcon
            icon={["far", "credit-card"]}
            className="icon fa-3x"
          />

          <FontAwesomeIcon
            icon={["far", "money-bill-alt"]}
            className="icon fa-3x"
          />
        </div>
      </div>

      <Container>
        <div className="section-heading text-center">
          <span className="rounded-pill shadow-box bold py-2 px-4">
            <FontAwesomeIcon
              icon={["far", "lightbulb"]}
              className="text-primary mr-2"
            />
            <span className="text-primary">Save more</span> with these awesome
            plans
          </span>
          <h2 className="mt-3 heading-line">Transparent pricing</h2>
          <p className="lead text-muted">
            Our plans have everything you need to take your growth to the next
            level. <span className="highlight">No hidden fees.</span>
          </p>

          <ButtonGroup>
            <Button
              color="secondary"
              onClick={e => setBasis("monthly")}
              active={basis === "monthly"}
            >
              Monthly
            </Button>

            <Button
              color="secondary"
              onClick={e => setBasis("yearly")}
              active={basis === "yearly"}
            >
              Yearly
            </Button>
          </ButtonGroup>
        </div>

        <Row className="no-gutters align-items-center pricing-plans-options">
          {plans2.map((plan, i) => (
            <PricingPlan {...plan} basis={basis} key={i} extended={extended} />
          ))}
        </Row>
      </Container>

      <Container className="pt-0 border-bottom">
        <Row className="align-items-center">
          <Col md="8">
            <div className="text-center text-md-left">
              <h4 className="bold accent">Not sure what's better for you?</h4>
              <p className="mt-0">
                You can easily change your plan at any time. We will also remind you when you are running low on credits so you never miss a beat. There's also an Enterprise Plan for those who need Unlimited Volume, HIPAA, First-Party Data, etc.
                &nbsp;&nbsp;<span className="italic dotted">
                  Still worried? We even have a 60-Day Refund, No Questions Asked!*
                </span>
              </p>
            </div>
          </Col>

          <Col md="4" className="text-center text-md-right">
            <MoreInfoButton
              className="btn btn-primary btn-lg text-contrast p-4 ml-md-auto"
              text="Try it now !"
              to="https://app.sendboth.com"
            />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Pricing;
